import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const AppHeader = () =>(

    <header id="header" className="header fixed-top">
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

        <div className="logo d-flex align-items-center">
            <img className="outro" src="assets/img/logo_uda_c.png" alt="Logo UDA"/>
            <img src="assets/img/logo_utd.png" alt="Logo UTD"/>
            <img className="outro" src="assets/img/logo_min.jpeg" alt="Logo Ministerio de Desarrollo"/>
            {/* <span>UDA</span> */}
        </div>

        <nav id="navbar" className="navbar">
            <ul>
            <li><HashLink smooth to="/#hero" className="nav-link scrollto active">Inicio</HashLink></li>
            <li><HashLink smooth to="/#mision" className="nav-link scrollto">Identidad</HashLink></li>
            <li><HashLink smooth to="/#seminarios" className="nav-link scrollto">Seminarios</HashLink></li>
            <li><HashLink smooth to="/#talleres" className="nav-link scrollto">Talleres</HashLink></li>
            <li><HashLink smooth to="/#contacto" className="nav-link scrollto">Contacto</HashLink></li>
            <li><HashLink smooth to="/proyectos" className="nav-link scrollto">Proyectos</HashLink></li>
            <li><a className="nav-link scrollto" href="mailto:utdigital@uda.edu.ar" rel="noreferrer"><img src="assets/img/email.png" alt="Email" width="30px"/></a></li>
            {/* <li><a className="nav-link scrollto" href="https://wa.me/549124578966" target="_blank"><img src="assets/img/whatsapp.png" alt="WhatsApp" width="30px"/></a></li>
            <li><a className="nav-link scrollto" href="https://instagram.com/uda" target="_blank"><img src="assets/img/instagram.png" alt="Instagram" width="30px"/></a></li> */}
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>

        </div>
    </header>

);

export default AppHeader;