import * as React from 'react';
const SeminarioMain = () => (
    <section id="seminarios" class="values">

      <div class="container">

        <header class="section-header">
          <div align="right">
            <h1>Seminarios</h1>
          </div>
        </header>

        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              
                <div class="card-header">
                  <h5 class="card-title">GPS Interior</h5>
                  <div class="row">
                    <div class="col-sm-6" align="left">
                      <img src="assets/img/calendar_b.png" alt="Calendario" width="20px" align="left"/>
                      <h6 class="card-date">9 de noviembre 2023</h6>
                    </div>
                    <div class="col-sm-6" align="right">
                      <span class="text-modal">MODALIDAD ONLINE - 18 a 20hs</span>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <p class="card-text">
                    Liderazgo e innovación, los factores más importantes para asegurar el funcionamiento general de las empresas, 
                    así como para facilitar sus procesos de transformación. Liderar hacia la innovación. <br/>  <br/>
                  </p>

                  <div class="row">
                  <div class="col-sm-6">
                    {/* <img src="assets/img/foto_jpl.jpg" alt="Calendario" width="45px" align="left"/>
                    <span class="prof-name">Juan Pablo López</span><br/>
                    <span class="prof-title">Lic. en Economía - Doc. Universitario</span> */}
                  </div>

                  <div class="col-sm-6 align-self-center">
                    <div align="center">
                      <a href="https://forms.gle/55DZo7qm3c5hFRqY9" target="_blank" class="btn btn-primary">Inscribirse</a>
                    </div>
                  </div>
                </div>

                

              </div>
              <div class="card-footer text-muted" align="right">
                <img src="assets/img/logo_uda_c.png" height="15px" alt="Logo UDA"/>
                <img src="assets/img/logo_utd.png" height="15px" alt="Logo UTD"/>
                <img src="assets/img/logo_min.jpeg" height="15px" alt="Logo Ministerio de Desarrollo"/>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="card">
              
                <div class="card-header">
                  <h5 class="card-title">Customer Relationship Management (CRM)</h5>
                  <div class="row">
                    <div class="col-sm-6" align="left">
                      <img src="assets/img/calendar_b.png" alt="Calendario" width="20px" align="left"/>
                      <h6 class="card-date">29 de noviembre 2023</h6>
                    </div>
                    <div class="col-sm-6" align="right">
                      <span class="text-modal">MODALIDAD ONLINE - 18 a 20hs</span>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                <p class="card-text">
                  Un enfoque para gestionar la interacción de una empresa con sus clientes actuales y potenciales. 
                  Utiliza el análisis de datos para mejorar las relaciones comerciales con tus clientes, centrándose 
                  específicamente en la retención de los mismos y, en última instancia, impulsando el crecimiento de las ventas. 
                </p> 
                <div class="row">
                  <div class="col-sm-6">
                    {/* <img src="assets/img/foto_jpl.jpg" alt="Calendario" width="45px" align="left"/>
                    <span class="prof-name">Juan Pablo López</span><br/>
                    <span class="prof-title">Lic. en Economía - Doc. Universitario</span> */}
                  </div>

                  <div class="col-sm-6 align-self-center">
                    <div align="center">
                      <a href="https://forms.gle/55DZo7qm3c5hFRqY9" target="_blank" class="btn btn-primary">Inscribirse</a>
                    </div>
                  </div>
                </div>

                

              </div>
              <div class="card-footer text-muted" align="right">
                <img src="assets/img/logo_uda_c.png" height="15px" alt="Logo UDA"/>
                <img src="assets/img/logo_utd.png" height="15px" alt="Logo UTD"/>
                <img src="assets/img/logo_min.jpeg" height="15px" alt="Logo Ministerio de Desarrollo"/>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              
                <div class="card-header">
                  <h5 class="card-title">Enterprise resource planning (ERP)</h5>
                  <div class="row">
                    <div class="col-sm-6" align="left">
                      <img src="assets/img/calendar_b.png" alt="Calendario" width="20px" align="left"/>
                      <h6 class="card-date">Diciembre 2023</h6>
                    </div>
                    <div class="col-sm-6" align="right">
                      <span class="text-modal">PRÓXIMAMENTE</span>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                <p class="card-text">
                  Sistemas de información gerenciales que integran y manejan muchos de los negocios asociados con las 
                  operaciones de producción y de los aspectos de distribución de una compañía en la producción de bienes o servicios.
                </p>
                

                <div class="row">
                  <div class="col-sm-6">
                    {/* <img src="assets/img/foto_jpl.jpg" alt="Calendario" width="45px" align="left"/>
                    <span class="prof-name">Juan Pablo López</span><br/>
                    <span class="prof-title">Lic. en Economía - Doc. Universitario</span> */}
                  </div>

                  <div class="col-sm-6 align-self-center">
                    <div align="center">
                      <a href="https://forms.gle/55DZo7qm3c5hFRqY9" target="_blank" class="btn btn-primary">Inscribirse</a>
                    </div>
                  </div>
                </div>

              </div>
              <div class="card-footer text-muted" align="right">
                <img src="assets/img/logo_uda_c.png" height="15px" alt="Logo UDA"/>
                <img src="assets/img/logo_utd.png" height="15px" alt="Logo UTD"/>
                <img src="assets/img/logo_min.jpeg" height="15px" alt="Logo Ministerio de Desarrollo"/>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="card">
              
                <div class="card-header">
                  <h5 class="card-title">Analítica de Datos</h5>
                  <div class="row">
                    <div class="col-sm-6" align="left">
                      <img src="assets/img/calendar_b.png" alt="Calendario" width="20px" align="left"/>
                      <h6 class="card-date">Enero 2024</h6>
                    </div>
                    <div class="col-sm-6" align="right">
                      <span class="text-modal">PRÓXIMAMENTE</span>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                <p class="card-text">
                  El valor de la información que se obtiene del análisis de datos, y su utilidad para las instituciones 
                  financieras. Herramientas de software habitualmente utilizadas para la captura, gestión y procesamiento de Big Data.
                </p>
                <div class="row">
                  <div class="col-sm-6">
                    {/* <img src="assets/img/foto_jpl.jpg" alt="Calendario" width="45px" align="left"/>
                    <span class="prof-name">Juan Pablo López</span><br/>
                    <span class="prof-title">Lic. en Economía - Doc. Universitario</span> */}
                  </div>

                  <div class="col-sm-6 align-self-center">
                    <div align="center">
                      <a href="https://forms.gle/55DZo7qm3c5hFRqY9" target="_blank" class="btn btn-primary">Inscribirse</a>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="card-footer text-muted" align="right">
                <img src="assets/img/logo_uda_c.png" height="15px" alt="Logo UDA"/>
                <img src="assets/img/logo_utd.png" height="15px" alt="Logo UTD"/>
                <img src="assets/img/logo_min.jpeg" height="15px" alt="Logo Ministerio de Desarrollo"/>
              </div>
            </div>
          </div>

        </div>

        

      </div>

    </section>
);

export default SeminarioMain;