import * as React from 'react';
const TalleresMain = () => (
    <section id="talleres" class="talleres">

      <div class="container">

        <header class="section-header">
          <div align="left">
            <h1>Talleres</h1>
          </div>
        </header>

        <div class="row">
          <div align="right">
            <img src="assets/img/calendar_b.png" alt="Mundo" width="40px" align="right"/>
            <h2> Noviembre 2023 </h2>
          </div>
        </div>

        <div class="card">

          <div class="card-body">
                <div class="text-center">
                  <h5 class="card-title ">Transformación Digital: desafíos y beneficios</h5>
                </div>

                <div class="row">

                  <div class="col-sm-6">
                    <p class="card-text">
                      <br/>
                      En este nuevo ciclo de talleres, analizaremos los nuevos modelos de gestión y las herramientas digitales para llevarlos a cabo. Poniendo foco en  plataformas colaborativas.
                      Incluyendo, acciones y procedimientos de la industria 4.0 de  manera que la Mipyme tenga todos los recursos a su alcance para comenzar su transformación digital.
                    </p>
                  </div>

                  <div class="col-sm-6 align-self-center">
                    <div align="center">
                      <a href="https://forms.gle/55DZo7qm3c5hFRqY9" class="btn btn-primary" target="_blank">Inscribirse</a>
                    </div>
                  </div>

                </div>
            
          </div>

        </div>

        <div class="row">
          <div align="right">
            <img src="assets/img/calendar_b.png" alt="Mundo" width="40px" align="right"/>
            <h2> Diciembre 2023 </h2>
          </div>
        </div>

        <div class="card text-left">
            <div class="card-body">
              
              <div class="text-center">
                <h5 class="card-title ">Transformación Digital: desafíos y beneficios</h5>
              </div>

              <div class="row">

                  <div class="col-sm-6">
                  <p class="card-text">
                      <br/>
                      En este nuevo ciclo de talleres, analizaremos los nuevos modelos de gestión y las herramientas digitales para llevarlos a cabo. Poniendo foco en  plataformas colaborativas.
                      Incluyendo, acciones y procedimientos de la industria 4.0 de  manera que la Mipyme tenga todos los recursos a su alcance para comenzar su transformación digital.
                    </p>
                  </div>

                  <div class="col-sm-6 align-self-center">
                    <div align="center">
                      <a href="https://forms.gle/55DZo7qm3c5hFRqY9" class="btn btn-primary" target="_blank">Inscribirse</a>
                    </div>
                  </div>

                </div>

            </div>
        </div>

     </div>

    </section>
);

export default TalleresMain;