import {
    Route,
    Routes,
    useLocation, 
  } from "react-router-dom";
import * as React from 'react';
import HomePage from "../pages/Home";
import ProyectoPage from "../pages/Proyectos";
import { AnimatePresence } from 'framer-motion';

function AnimatedRoutes() {
    const location = useLocation();
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<HomePage/>} />
                <Route strict path="/proyectos" element={<ProyectoPage/>} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes