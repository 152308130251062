import AppHeader from '../components/headerApp/headerApp';
import AppMain from '../components/mainApp/mainApp';
import HeroApp from '../components/heroApp/heroApp';
import FooterMain from '../components/footerMain/footerMain';
import {useEffect} from 'react';
import { motion } from 'framer-motion';

export default function HomePage() {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <AppHeader />
            <HeroApp />
            <AppMain />
            <FooterMain />
       </motion.div>
    );
}