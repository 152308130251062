import MisionMain from "./misionMain/misionMain";
import SeminarioMain from "./seminarioMain/seminarioMain";
import TalleresMain from "./talleresMain/talleresMain";
import ContactoMain from "./contactoMain/contactoMain";
import * as React from 'react';

const AppMain = () =>(

    <main id="main">
        
    <MisionMain />
    <SeminarioMain />
    <TalleresMain />
    <ContactoMain />

  </main>
);

export default AppMain;