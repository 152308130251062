import * as React from 'react';
const ContactoMain = () => (
    <section id="contacto" class="contact">

      <div class="container">
        <div class="row">
            <div align="right">
                <h1>Contacto</h1>
            </div>
            <br />
            <div align="right">
                <div class="col-lg-6">
                    <form id="contact" action="contacto.php" method="post">
                        <div class="row gy-4">
                            
                            <div class="col-md-6">
                                <input type="text" name="nombre" class="form-control" placeholder="Nombre" required/>
                            </div>

                            <div class="col-md-6 ">
                                <input type="text" class="form-control" name="apellido" placeholder="Apellido" required/>
                            </div>

                            <div class="col-md-12">
                                <input type="email" class="form-control" name="email" placeholder="Correo" required/>
                            </div>

                            <div class="col-md-12">
                                <textarea class="form-control" name="mensaje" required> </textarea>
                            </div>

                            <div class="col-md-12 text-center">
                                <button class="btn" name="submit" type="submit" id="contact-submit" data-submit="...Enviar">Enviar mensaje</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>

    </section>
);

export default ContactoMain;