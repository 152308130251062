import {useEffect} from 'react';
import { motion } from 'framer-motion';
import AppHeader from '../components/headerApp/headerApp';
import FooterMain from '../components/footerMain/footerMain';
import Proyecto from '../components/proyectos/proyectos';

export default function ProyectoPage() {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <AppHeader/>
            <Proyecto/>
            <FooterMain/>
       </motion.div>
    );
}