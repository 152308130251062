const Proyecto = () => (
  <section id="features" className="features">
    <div className="container aos-init aos-animate" data-aos="fade-up">
      <header className="section-header">
        <br />
        <br />
        <br />
        <p>¿Qué es Open Sayges?</p>
      </header>

      <div className="row">
        <div className="col-lg-6">
          {" "}
          <img src="assets/img/recurso1.png" className="img-fluid" alt="" />
        </div>

        <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
          <div className="row align-self-center gy-4">
            Open SAYGES es un proyecto de la Universidad del Aconcagua que cubre
            todas las necesidades de una organización tanto privada como
            gubernamental, en lo que respecta a desarrollos e implementación de
            procesos, automatización, gestión documental electrónica, firma
            digital y atención online al cliente, con la posibilidad de integrar
            otros sistemas preexistentes, con múltiples características y
            componentes.
          </div>
        </div>
      </div>
    </div>

    <div className="container aos-init aos-animate" data-aos="fade-up">
      <br />
      <br />
      
      <header className="section-header">
      <img
        src="assets/img/recurso17.png"
        class="img-footer-hero"
        alt=""
        height="80px"
      />
      <br />
      <br />
        <p>Proyecto Open Sayges</p>
      </header>

      <div className="row">
        <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
          <div className="row align-self-center gy-4">
            Somos una herramienta fundamental para la modernización, innovación
            y adaptación de las organizaciones a las nuevas tendencias
            tecnológicas del mercado.
          </div>
        </div>
      </div>
    </div>

    <div className="container aos-init aos-animate" data-aos="fade-up">
      <header className="section-header">
        <br />
        <p>Nuestra historia</p>
      </header>

      <div className="row">
        <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
          <div className="row align-self-center gy-4">
            <p>
              Hace 50 años los investigadores Jorge Sábato y Natalio Botana
              plantearon en su artículo “La Ciencia y la tecnología en el
              desarrollo futuro de América Latina” la conceptualización de la
              relación Universidad-Empresa-Estado, conocida como el triángulo de
              Sábato.
            </p>
            <p>
              “No basta una vigorosa infraestructura científico–tecnológica para
              asegurar que un país será capaz de incorporar la ciencia y la
              técnica a su proceso de desarrollo: es menester, además,
              transferir a la realidad los resultados de la investigación;
              acoplar la infraestructura científico–tecnológica a la estructura
              productiva de la sociedad”.
            </p>
            <p>
              Esta idea disruptiva muy vigente 50 años después nos ha impulsado
              a liderar el proyecto denominado Open Sayges, surgido de
              investigaciones y de trabajos de nuestras Facultades, haciendo
              realidad la transferencia de resultado de investigación, acoplando
              la infraestructura científico tecnológica con la estructura social
              y productiva de nuestra sociedad.
            </p>
            <p>
              La participación del Gobierno a través de sus Agencias y de las
              empresas con sus equipos de I+D que han trabajado de manera
              articulada con nuestros investigadores han permitido alcanzar
              resultados concretos y beneficios para los ciudadanos en la nueva
              era digital.
            </p>
            <p>
              Entendemos que este camino recién comienza y son numerosos los
              desafíos y metas alcanzar pero estamos convencidos que las
              alianzas estratégicas que llevamos adelante son un impulso para
              alcanzar los objetivos propuestos por estos líderes investigadores
              argentinos Sábato y Botana en pos y en mejora de nuestra sociedad.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container aos-init aos-animate" data-aos="fade-up">
      <header className="section-header">
        <p>Objetivos del proyecto</p>
      </header>

      <div className="row">
        <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
          <div className="row align-self-center gy-4">
            Buscamos acercar la organización (Gobierno, Municipio, Empresas y
            otros) al ciudadano, informando, reduciendo los tiempos de espera y
            agilizando sus trámites. Controlar la actividad, detectar y corregir
            demoras y emprender un ciclo de mejora continua en la comunicación,
            atención de solicitudes y tramitación con los ciudadanos.
            Facilitando los procesos de mejora y de transformación digital de
            las organizaciones por medio de la automatización de los procesos
            administrativos y la normalización de los procedimientos buscando
            entre otros los siguientes beneficios:
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
        <div class="row items-abajo">
          <div class="col-lg-3">
            <div class="box">
              <img
                src="assets/img/recurso2.png"
                class="img-footer-hero"
                alt=""
              />
              <h6 class="tex-from-icon">
                Mejorar los procesos y reducir los tiempos de ejecución.
              </h6>
            </div>
          </div>
          <div class="col-lg-3 mt-4 mt-lg-0">
            <div class="box">
              <img
                src="assets/img/recurso3.png"
                class="img-footer-hero"
                alt=""
              />
              <h6 class="tex-from-icon">
                Mejorar la comunicación interna de la propia organización.
              </h6>
            </div>
          </div>
          <div class="col-lg-3 mt-4 mt-lg-0">
            <div class="box">
              <img
                src="assets/img/recurso4.png"
                class="img-footer-hero"
                alt=""
              />
              <h6 class="tex-from-icon">
                Ayudar al cumplimiento de las distintas legislaciones vigentes.
              </h6>
            </div>
          </div>
          <div class="col-lg-3 mt-4 mt-lg-0">
            <div class="box">
              <img
                src="assets/img/recurso5.png"
                class="img-footer-hero"
                alt=""
              />
              <h6 class="tex-from-icon">
                Mayor transparencia y seguridad de toda la información.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <header className="section-header">
        <br />
        <br />
        <p>Organizaciones que son parte de nuestro proyecto</p>
      </header>

      <div className="row">
        <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
          <div className="row align-self-center gy-4">
            Quienes confiaron y optimizaron su administración implementando la
            herramienta digital Open SayGes o productos derivados de la
            solución.
            <br />
            <br />
          </div>
        </div>
        <div class="row items-abajo">
          <div class="col-lg-3">
            <div class="box">
              <img
                src="assets/img/recurso6.png"
                class="img-footer-hero"
                alt=""
                height="80px"
              />
            </div>
          </div>
          <div class="col-lg-3 mt-4 mt-lg-0">
            <div class="box">
              <img
                src="assets/img/recurso7.jpg"
                class="img-footer-hero"
                alt=""
                height="80px"
              />
            </div>
          </div>
          <div class="col-lg-3 mt-4 mt-lg-0">
            <div class="box">
              <img
                src="assets/img/recurso8.jpg"
                class="img-footer-hero"
                alt=""
                height="80px"
              />
            </div>
          </div>
          <div class="col-lg-3 mt-4 mt-lg-0">
            <div class="box">
              <img
                src="assets/img/recurso9.jpg"
                class="img-footer-hero"
                alt=""
                height="80px"
              />
              <br />
              <br />
            </div>
          </div>
        </div>
        <div class="row items-abajo">
          <div class="col-lg-3">
            <div class="box">
              <img
                src="assets/img/recurso10.jpg"
                class="img-footer-hero"
                alt=""
                height="80px"
              />
            </div>
          </div>
          <div class="col-lg-3 mt-4 mt-lg-0">
            <div class="box">
              <img
                src="assets/img/recurso11.png"
                class="img-footer-hero"
                alt=""
                height="50px"
              />
            </div>
          </div>
          <div class="col-lg-3 mt-4 mt-lg-0">
            <div class="box">
              <img
                src="assets/img/recurso12.png"
                class="img-footer-hero"
                alt=""
                height="80px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container aos-init aos-animate" data-aos="fade-up">
      <header className="section-header">
        <br />
        <br />
        <p>Principales tecnologías</p>
      </header>

      <div className="row">
        <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
          <div className="row align-self-center gy-4">
            <br />
            <br />
            <br />
          </div>
        </div>
        <div class="row items-abajo">
          <div class="col-lg-3">
            <div class="box">
              <img
                src="assets/img/recurso13.jpg"
                class="img-footer-hero"
                alt=""
                height="80px"
              />
              <h6 class="tex-from-icon">
                <br />
                ZK es el mejor marco Java de código abierto para crear
                aplicaciones móviles y web empresariales.
              </h6>
            </div>
          </div>
          <div class="col-lg-3 mt-4 mt-lg-0">
            <div class="box">
              <img
                src="assets/img/recurso14.jpg"
                class="img-footer-hero"
                alt=""
                height="80px"
              />
              <h6 class="tex-from-icon">
                <br />
                Plataforma extensible de código abierto para la automatización y
                optimización de procesos comerciales.
              </h6>
            </div>
          </div>
          <div class="col-lg-3 mt-4 mt-lg-0">
            <div class="box">
              <img
                src="assets/img/recurso15.jpg"
                class="img-footer-hero"
                alt=""
                height="80px"
              />
              <h6 class="tex-from-icon">
                <br />
                Plataforma de análisis empresarial de un extremo a otro.
              </h6>
            </div>
          </div>
          <div class="col-lg-3 mt-4 mt-lg-0">
            <div class="box">
              <img
                src="assets/img/recurso16.jpg"
                class="img-footer-hero"
                alt=""
                height="80px"
              />
              <h6 class="tex-from-icon">
                <br />
                Servicio de almacenamiento de objetos que ofrece escalabilidad,
                disponibilidad de datos, seguridad y rendimiento.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Proyecto;
