import * as React from 'react';
const MisionMain = () => (

    <section id="mision" class="about">
      
      <div class="container">
        <div class="row">
          <div align="left">
            <h1>Industria 4.0 </h1>
          </div>
          <div class="col-lg-6">
            <p align="left">
              Primer Ciclo de Seminarios temáticos y Talleres, para la promoción de la competitividad, 
              innovación y desarrollo productivo de PyMEs, MiPyMEs, emprendedores/as y nuevas empresas.
              <br/>
              Con el fin de brindar herramientas que dinamicen el proceso de Transformación Digital en 
              la industria, la Unidad de Transformación Digital de la Universidad del Aconcagua propone 
              generar un espacio de intercambio de experiencias, de servicio de consultoría, de 
              implementación de soluciones 4.0 y de mejora de las condiciones de las estructuras 
              productivas regionales.
            </p>
          </div>
        </div>
      </div>

    </section>

);

export default MisionMain;