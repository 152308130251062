import { HashRouter } from "react-router-dom";
import AnimatedRoutes from "./components/AnimatedRoutes";
import * as React from 'react';

export default function App() {
  return (
      <HashRouter>
        <AnimatedRoutes />
      </HashRouter>
  );
}