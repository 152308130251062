const AppHero = () =>(

<section id="hero" class="d-flex align-items-center justify-content-center">
    <div class="container">

      <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-8">
          <h1>Unidad de Transformación Digital</h1>
          <h2>Zona Cuyo - Universidad del Aconcagua</h2>
        </div>
      </div>

      {/* <div class="row gy-4 mt-5 justify-content-center">
        <div class="col-xl-2 col-md-4">
          <img src="assets/img/logo_uda.png" alt="Logo UTD" width="200px" height="auto"/>
        </div>
        <div class="col-xl-2 col-md-4">
          <img src="assets/img/logo_min.png" alt="Logo Ministerio de Desarrollo" width="200px" height="auto"/>
        </div>
      </div> */}

    </div>
  </section>

);

export default AppHero;