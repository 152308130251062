const FooterMain = () => (
  
    <section id="footer" className="footer">
      
      <div className="footer-content">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 col-md-6" align="left">
              <h4>CONTACTO</h4>
              <p>
                Catamarca 147, Mendoza, <br />
                Argentina<br />
                <a href="https://wa.me/5492615455096" target="_blank">+54 9 2615 45-5096</a> <br />
                <a href="mailto:utdigital@uda.edu.ar" target="_blank">utdigital@uda.edu.ar</a> <br />
              </p>
            </div>

            <div className="col-lg-3 col-md-6 footer-links" align="left">
              <h4>HORARIOS DE ATENCIÓN</h4>
              <p>
              Lunes a Viernes de 8.30 a 12.30<br />
              </p>
            </div>

            <div className="col-lg-3 col-md-6 footer-links" align="left">
            <h4>REDES SOCIALES</h4>
                <a href="https://instagram.com/UTDigitalUDA" target="_blank"><img src="assets/img/instagram-f.png" alt="Instagram" width="30px"/> @UTDigitalUDA <br /></a><br />
                <a href="https://twitter.com/UTDigitalUDA" target="_blank"><img src="assets/img/twitter-f.png" alt="Twitter" width="30px"/> @UTDigitalUDA</a><br />
            </div>

            <div className="col-lg-3 col-md-6 footer-newsletter">
              <a href="http://www.ciudaduniversitaria.gov.ar/" target="_blank"><img src="assets/img/banner3.jpg" alt="WhatsApp" width="150px"/></a><br /><br />
              <a href="http://radu.edu.ar/" target="_blank"><img src="assets/img/banner1.jpg" alt="WhatsApp" width="150px"/></a><br /><br />
            </div>

          </div>
        </div>
      </div>

    </section>
);

export default FooterMain;